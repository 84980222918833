// declare gtag as global function as it is defined in the index.html asynchronously
declare var gtag: Function;

/**
 * A class you can extend for gtag functionality
 */
export abstract class GaTaggable {
  readonly _isGTagAvailable: boolean = false;

  constructor() {
    this._isGTagAvailable = typeof gtag === 'function';
  }

  public gTagClick(logObject: object){
    if(this._isGTagAvailable) {
      gtag('event', 'click', logObject);
    }
  }
}
