import {Directive, HostListener, Input} from '@angular/core';
import {GaTaggable} from '@src/app/helpers/ga-taggable';

@Directive({
  selector: '[gaTag]',
})
export class GoogleAnalyticsEventDirective extends GaTaggable {
  @Input('gaTag') gaTag: string = 'NOT_DEFINED';

  constructor() {
    super();

    if (!this._isGTagAvailable) {
      console.warn(`Google Analytics is not initialized at directive instantiation with tag: ${this.gaTag}`);
    }
  }

  // Listen for click events on the host element
  @HostListener('click')
  onClick() {
    if (this._isGTagAvailable) {
      const date = new Date();
      const time = date.toLocaleDateString() + '_' + date.toLocaleTimeString()
      // Send the Google Analytics event when the element is clicked
      this.gTagClick({
        'GA-tag': this.gaTag,
        'dateTime': time
      });
    } else {
      console.warn('Google Analytics is not initialized');
    }
  }
}
