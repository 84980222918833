/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ColorType {
  Orange = "Orange",
  Purple = "Purple",
  Red = "Red",
  Yellow = "Yellow",
}

export enum ModuleType {
  Aux = "Aux",
  Main = "Main",
}

export enum VariantTranslationKey {
  Description = "Description",
  Title = "Title",
}

export enum WebhookFlowType {
  codegetredirect = "codegetredirect",
  gammasendmessage = "gammasendmessage",
  hornbachsendmessage = "hornbachsendmessage",
}

export enum WoodType {
  douglas = "douglas",
  grenen = "grenen",
}

export interface AttachmentInput {
  placementId: any;
  yAttachmentId: any;
}

export interface ConfigurationPlacementInput {
  placements: ModulePlacementInput[];
  originPlacement: any;
  woodType: WoodType;
}

export interface IdModulePlacementListSpacialPositionInputInputMap {
  values: IdModulePlacementListSpacialPositionInputInputPair[];
}

export interface IdModulePlacementListSpacialPositionInputInputPair {
  key: any;
  value: SpacialPositionInput[];
}

export interface IdXAttachmentPointAttachmentInputMap {
  values: IdXAttachmentPointAttachmentInputPair[];
}

export interface IdXAttachmentPointAttachmentInputPair {
  key: any;
  value: AttachmentInput;
}

export interface Matrix4x4Input {
  r1: Matrix4x4RowInput;
  r2: Matrix4x4RowInput;
  r3: Matrix4x4RowInput;
  r4: Matrix4x4RowInput;
}

export interface Matrix4x4RowInput {
  c1: number;
  c2: number;
  c3: number;
  c4: number;
}

export interface ModulePlacementInput {
  id: any;
  variantId: any;
  transformation?: Matrix4x4Input | null;
  xAttachments: IdXAttachmentPointAttachmentInputMap;
}

export interface RenderStateInput {
  cameraPosition: Vec3Input;
  cameraRotation: Vec3Input;
  controlTarget: Vec3Input;
}

export interface SpacialPositionInput {
  position: Vec3Input;
  orientation?: Vec3Input | null;
}

export interface UndoConfigurationInput {
  configurationPlacement: ConfigurationPlacementInput;
  date: any;
}

export interface Vec3Input {
  x: number;
  y: number;
  z: number;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
